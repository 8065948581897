/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

@tailwind base;
@tailwind components;
@tailwind utilities;

/*.form-input {*/
/*    @apply w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:ring-opacity-50;*/
/*}*/
/*.form-input {*/
/*    @apply w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:ring-opacity-50;*/
/*}*/
.form-input {
    @apply text-black rounded-none block py-2.5 px-0 w-full text-sm focus:bg-blue-50 hover:bg-blue-50 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600
}
.form-label {
    @apply block text-sm font-medium text-gray-600
}